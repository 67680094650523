import { mapGetters } from 'vuex';

import hasNavigationAccess from '@/helpers/hasNavigationAccess';

import WebSocketService from 'Services/websocket/websocket';
import websocketUrls from 'Services/websocket/endpoints';

import routes from '@/router/routes';
import { notificationCommands, notificationTypes } from '@/constants/notifications';

import NavigationDrawer from './navigationDrawer/NavigationDrawer.vue';
import NavigationTopBar from './navigationTopBar/NavigationTopBar.vue';

export default {
  components: {
    NavigationDrawer,
    NavigationTopBar,
  },

  data() {
    return {
      links: [],
      newMessagesCount: 0,
      newNotificationsCount: 0,
      routes,
      socket: null,
      isShowingDrawer: false,
      isShowingRail: true,
    };
  },

  computed: {
    ...mapGetters('auth', ['isProfessional', 'hasCoachingEnabled']),
    ...mapGetters(
      'enrollments',
      ['activeEnrollment', 'activeOrganisation', 'questionnaireIsEnabled'],
    ),

    accessibleLinks() {
      return this.links.filter((link) => link.canAccess && link.isActive);
    },
  },

  watch: {
    activeEnrollment() {
      this.onSocketOpen();
      this.links = this.getLinks();
      const { meta } = this.$route;

      if (meta.switchOrganisationsNotAllow || (!hasNavigationAccess(meta))) {
        this.$router.push(routes('dashboard'));
      }
    },

    isShowingRail(showRail) {
      this.isShowingRail = showRail;
    },
  },

  created() {
    this.createWebSocket();
    this.links = this.getLinks();
  },

  beforeUnmount() {
    this.closeWebSocket();
  },

  methods: {
    closeDrawer() {
      if (this.$vuetify.display.lg && !this.isShowingRail) this.isShowingRail = true;
    },

    hideDrawer() {
      if (this.$vuetify.display.mdAndDown) this.isShowingDrawer = false;
      else this.isShowingRail = true;
    },

    async createWebSocket() {
      this.socket = await WebSocketService(websocketUrls('notifications'), { reconnect: true });
      this.socket.onopen = this.onSocketOpen;
      this.socket.onmessage = this.onMessage;
    },

    closeWebSocket() {
      if (!this.socket?.close) return;
      this.socket.close(1000);
    },

    onSocketOpen() {
      this.requestUnreadMessages();
      this.requestUnreadNotifications();
    },

    requestUnreadMessages() {
      this.requestUnreadCommand(notificationCommands.FETCH_UNREAD_MESSAGES);
    },

    requestUnreadNotifications() {
      this.requestUnreadCommand(notificationCommands.FETCH_UNREAD_NOTIFICATIONS);
    },

    onMessage(event) {
      const { UNREAD_MESSAGES, UNREAD_NOTIFICATIONS } = notificationTypes;
      const { message, type } = JSON.parse(event.data);
      if (type === UNREAD_MESSAGES) this.newMessagesCount = message;
      if (type === UNREAD_NOTIFICATIONS) this.newNotificationsCount = message;
    },

    requestUnreadCommand(command) {
      const payload = { organisation_id: this.activeOrganisation.id };
      this.socket.send(JSON.stringify({ command, payload }));
    },

    getRouteMeta(url) {
      return url
        ? this.$router.options.routes.find(({ path }) => path === url).meta
        : null;
    },

    getLinks() {
      return [
        {
          text: ['general.titles.dashboard'],
          url: routes('dashboard'),
          icon: 'home',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('dashboard'))),
          isActive: true,
        },
        {
          text: ['general.titles.patients', 0],
          url: routes('care_plan_participants'),
          icon: 'patients',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('care_plan_participants'))),
          isActive: true,
        },
        {
          text: ['general.titles.carePlan'],
          url: routes('patient_care_plans'),
          icon: 'carePlan',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('patient_care_plans'))),
          isActive: true,
        },
        {
          text: ['general.titles.treatmentList'],
          url: routes('treatment_list', this.activeOrganisation ? this.activeOrganisation.id : null),
          icon: 'treatment',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('treatment_list'))),
          isActive: this.isProfessional,
        },
        {
          text: ['general.titles.questionnaireList'],
          url: routes('questionnaire_list', this.activeOrganisation ? this.activeOrganisation.id : null),
          icon: 'questionnaire',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('questionnaire_list'))),
          isActive: this.questionnaireIsEnabled,
        },
        {
          text: ['general.titles.monitor'],
          url: routes('monitor'),
          icon: 'monitor',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('monitor'))),
          isActive: true,
        },
        {
          text: ['general.titles.coaching'],
          url: routes('coaching'),
          icon: 'coaching',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('coaching'))),
          isActive: this.hasCoachingEnabled,
        },
        {
          text: ['general.titles.administration'],
          url: routes('care_plan_participants_admin'),
          icon: 'administration',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('care_plan_participants_admin'))),
          isActive: true,
        },
        {
          text: ['general.titles.groups'],
          url: routes('groups'),
          icon: 'patients',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('groups'))),
          isActive: true,
        },
        {
          text: ['general.titles.diary'],
          url: routes('diary'),
          icon: 'diary',
          canAccess: hasNavigationAccess(this.getRouteMeta(routes('diary'))),
          isActive: true,
        },
      ];
    },
  },
};
