import pendoMixin from '@/mixins/pendoMixin';

import {
  VApp,
} from 'vuetify/components';

import ErrorBoundary from 'Components/errorBoundary/errorBoundary';
import ErrorPage from 'Components/errorPage/errorPage';
import NavigationComponent from 'Components/navigationComponent/NavigationComponent.vue';
import NotificationBar from 'Components/notifications/notificationBar/NotificationBar.vue';

export default {
  components: {
    ErrorBoundary,
    ErrorPage,
    NotificationBar,
    NavigationComponent,
    VApp,
  },

  mixins: [pendoMixin],

  computed: {
    navigationBar() { return this.$route.meta.navigationBar; },
  },

  created() {
    this.initializePendo();
  },
};
