<template>
  <div
    class="notification-panel"
    @scroll="onScroll"
  >
    <slot />
  </div>
</template>

<script src="./notificationPanel.js"></script>

<style lang="scss">
  .notification-panel {
    display: flex;
    flex-direction: column;
    border: .1rem solid $neutral-40;
    border-top: .2rem solid $warning-60;
    border-radius: $radius-default;
    max-height: calc(80vh - 6rem);
    min-height: 10rem;
    width: 60vw;
    z-index: map-get($zindex, dropdown);
    padding: 1rem 2rem;
    overflow-y: auto;
    background-color: $neutral-0;
    box-shadow: 0 .1rem .4rem 0 rgba($neutral-100, 0.16);
    @include media-breakpoint-up(lg) {
      width: 40rem;
      max-height: 68rem;
    }
  }
</style>
