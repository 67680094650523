<template>
  <div
    v-click-outside="closeDrawer"
    class="nav-bar-app"
  >
    <NavigationTopBar
      :new-messages-count="newMessagesCount"
      :new-notifications-count="newNotificationsCount"
      @toggle-drawer="isShowingDrawer = !isShowingDrawer"
      @toggle-rail="isShowingRail = !isShowingRail"
      @hide-drawer="hideDrawer"
      @request-unread-notifications="requestUnreadNotifications"
    />

    <NavigationDrawer
      v-model="isShowingDrawer"
      :links="accessibleLinks"
      :is-showing-rail="isShowingRail"
      @show-rail="(isShowingRailNewValue) => isShowingRail = isShowingRailNewValue"
    />
  </div>
</template>

<script src="./navigationComponent.js" />
