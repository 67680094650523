import 'vuetify/lib/styles/main.sass'; // eslint-disable-line import/extensions
import '@mdi/font/css/materialdesignicons.css'; // eslint-disable-line import/no-unresolved, import/extensions
import 'Static/scss/style.scss';
import { createApp } from 'vue';

// eslint-disable-next-line import/no-unresolved
import { createVuetify } from 'vuetify';

import Notifications from '@kyvg/vue3-notification';
import { SetupCalendar } from 'v-calendar';

import { setRefinerProject } from 'Services/refiner/refiner';

import i18n from '@/i18n';
import isDeployed from '@/helpers/isDeployed';
import { localPlugins, setDirectives } from '@/plugins/plugins';
import RouterView from '@/router/RouterView.vue';
import router from '@/router';
import store from '@/store/store';
import { initializeSentry } from 'Services/sentry/base';

import routerMiddleware from './router/routerMiddleware';

const vuetify = createVuetify({
  display: {
    mobileBreakpoint: 'md',
    thresholds: {
      xs: 0,
      sm: 414,
      md: 768,
      lg: 1024,
      xl: 1600,
    },
  },
});

// //////////////////// VUE 3 ////////////////////
const app = createApp(RouterView);

app.use(vuetify);
app.use(router);
app.use(store);
app.use(i18n);

app.use(localPlugins);
app.use(Notifications);
app.use(SetupCalendar, {});

setDirectives(app);
routerMiddleware(router);
// //////////////////// END VUE 3 ////////////////////

// //////////////////// SENTRY ////////////////////
if (import.meta.env.VITE_SENTRY_DSN) initializeSentry(app, router);
// //////////////////// END SENTRY ///////////////////

// //////////////////// REFINER //////////////////////
if (isDeployed()) setRefinerProject();
// //////////////////// END REFINER //////////////////

app.mount('#app');
