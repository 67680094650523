<template>
  <div class="button-notifications">
    <ActionButtonIcon
      v-click-outside="closeNotifications"
      class="button-notifications__button"
      data-test="button-notifications"
      :action="toggleNotifications"
    >
      <IconComponent name="bell" />
      <BadgeComponent
        v-if="hasNewNotifications"
        class="button-notifications__messages-badge"
        data-test="notifications-badge"
        :count="newNotificationsCount"
      />
    </ActionButtonIcon>
    <NotificationPanel
      v-if="notificationsAreOpen"
      class="button-notifications__notifications"
      @scrolled-to-bottom="getNotifications"
    >
      <span
        v-if="allNotificationsAreSeen"
        class="button-notifications__notification-empty"
        data-test="notifications-empty"
      >
        {{ $t('general.notifications.noNewNotification') }}
      </span>
      <NotificationMessage
        v-for="(notification, index) in notifications"
        :key="index"
        data-test="notification"
        :notification="notification"
      />
    </NotificationPanel>
  </div>
</template>

<script src="./buttonNotifications.js"></script>

<style lang="scss">
  .button-notifications {
    @include media-breakpoint-up(lg) {
      position: relative;
    }

    &__button {
      position: relative;
    }

    &__messages-badge {
      position: absolute;
      top: -0.2rem;
      right: -0.2rem;
    }

    &__notifications {
      position: absolute;
      top: 6rem;
      right: 0;
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 4.4rem;
        right: -2rem;
        left: auto;
      }
    }

    div.notification-message:last-of-type {
      .notification-message__divider {
        display: none;
      }
    }

    &__notification-empty {
      font-weight: $font-weight-semi-bold;
      color: $neutral-80;
      margin-bottom: 1rem;
    }
  }
</style>
