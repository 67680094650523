<template>
  <div :class="{ [$route.meta.viewClass]: $route.meta.viewClass }">
    <v-app>
      <NavigationComponent v-if="navigationBar" />

      <div
        class="container"
        :class="{ 'container--website': !navigationBar }"
      >
        <NotificationBar />

        <ErrorPage>
          <ErrorBoundary>
            <router-view :key="$route.path" />
          </ErrorBoundary>
        </ErrorPage>
      </div>
    </v-app>

    <div id="modal" />
  </div>
</template>

<script src="./routerView.js" />

<style lang="scss">
  .container {
    margin-top: 10rem;
    max-width: 100%;
    padding: 0 2.4rem 3.2rem;

    @include media-breakpoint-up(lg) {
      max-width: 102.4rem;
      padding: 0 7.2rem 3.2rem 10rem;
    }

    @media (min-width: 1200px) {
      padding: 0 3.2rem 3.2rem;
    }

    &--website {
      min-height: 100vh;
      margin-top: 0;
    }
  }
</style>
