import { mapGetters } from 'vuex';

import redirectToSupportPage from '@/helpers/redirectSupportPage';
import routes from '@/router/routes';

import icon from 'Static/logos/icon.svg';
import fullSvg from 'Static/logos/full.svg';

import ActionButtonIcon from 'Components/buttons/actionButtonIcon/ActionButtonIcon.vue';
import BadgeComponent from 'Components/badges/badgeComponent/BadgeComponent.vue';
import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';
import ImageMain from 'Components/imageMain/ImageMain.vue';

import {
  VAppBar, VAppBarNavIcon,
} from 'vuetify/components';

import ButtonNotifications from '../buttonNotifications/ButtonNotifications.vue';
import ButtonOrganisations from '../buttonOrganisations/ButtonOrganisations.vue';

export default {
  components: {
    ActionButtonIcon,
    BadgeComponent,
    ButtonNotifications,
    ButtonOrganisations,
    IconComponent,
    ImageMain,
    VAppBar,
    VAppBarNavIcon,
  },

  props: {
    newMessagesCount: {
      type: Number,
      default: 0,
    },
    newNotificationsCount: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      redirectToSupportPage,
      routes,
      fullSvg,
      icon,
    };
  },

  emits: ['toggle-drawer', 'toggle-rail', 'hide-drawer', 'request-unread-notifications'],

  computed: {
    ...mapGetters('auth', ['isProfessional']),
    ...mapGetters('enrollments', ['activeOrganisation']),

    isSmallViewport() {
      return this.$vuetify.display.smAndDown;
    },

    isMediumViewport() {
      return this.$vuetify.display.md;
    },

    isLargeViewport() {
      return this.$vuetify.display.lg;
    },

    isXLargeViewport() {
      return this.$vuetify.display.xlAndUp;
    },
  },

  methods: {
    toggleDrawer() {
      if (this.isSmallViewport || this.isMediumViewport) this.$emit('toggle-drawer');
      else this.$emit('toggle-rail');
    },
  },
};
