<template>
  <div class="button-organisations">
    <div
      v-click-outside="closeMenu"
      class="button-organisations__organisation"
      :class="{
        'button-organisations__organisation--clickable':
          inactiveEnrollments.length,
      }"
      data-test="profile-menu"
      @click="toggleMenu"
    >
      <div
        v-if="activeOrganisation.logo"
        class="button-organisations__organisation-logo"
        :style="{ backgroundImage: `url(${activeOrganisation.logo})` }"
      />

      <div
        v-else
        class="button-organisations__organisation-default-logo"
      >
        <span>{{ getOrganisationInitials(activeOrganisation.name) }}</span>
      </div>

      <span class="button-organisations__organisation-name truncate">
        {{ activeOrganisation.name }}

        <v-tooltip
          activator="parent"
          location="bottom"
          class="button-organisations__tooltip"
        >
          {{ activeOrganisation.name }}
        </v-tooltip>
      </span>

      <IconComponent
        class="button-organisations__organisation-dropdown-icon"
        :class="{
          'button-organisations__organisation-dropdown-icon--toggled': show,
          'button-organisations__organisation-dropdown-icon--hide':
            !inactiveEnrollments.length,
        }"
        name="dropDown"
      />
    </div>

    <transition name="fade">
      <div
        v-if="show"
        class="button-organisations__menu-container"
      >
        <div
          class="button-organisations__menu"
        >
          <div class="button-organisations__menu-header">
            <IconComponent
              class="button-organisations__menu-header__icon-component"
              name="close"
              @click="closeMenu"
            />
          </div>

          <button
            class="button-organisations__menu-item button-organisations__menu-item--selected"
            type="button"
          >
            <div
              v-if="activeOrganisation.logo"
              class="button-organisations__organisation-logo"
              :style="{ backgroundImage: `url(${activeOrganisation.logo})` }"
            />

            <div
              v-else
              class="button-organisations__organisation-default-logo"
            >
              <span>{{ getOrganisationInitials(activeOrganisation.name) }}</span>
            </div>

            <span class="button-organisations__organisation-name">
              {{ activeOrganisation.name }}
            </span>
          </button>

          <button
            v-for="({ organisation, id }) in inactiveEnrollments"
            :key="id"
            :data-test="`input-select-option-${organisation.name}`"
            class="button-organisations__menu-item"
            type="button"
            @click="() => selectOrganisation(id)"
          >
            <div
              v-if="organisation.logo"
              class="button-organisations__organisation-logo"
              :style="{ backgroundImage: `url(${organisation.logo})` }"
            />

            <div
              v-else
              class="button-organisations__organisation-default-logo"
            >
              <span>{{ getOrganisationInitials(organisation.name) }}</span>
            </div>

            <span class="button-organisations__organisation-name">
              {{ organisation.name }}
            </span>
          </button>
        </div>

        <div class="button-organisations__scrim" />
      </div>
    </transition>
  </div>
</template>

<script src="./buttonOrganisations.js"></script>

<style lang="scss">
.button-organisations {
  display: flex;
  flex-direction: column;
  z-index: map-get($zindex, dropdown);
  margin-left: 2.4rem;

  &__organisation {
    display: flex;
    align-items: center;
    gap: .8rem;
    padding: 0;
    @include media-breakpoint-up(md) {
      min-width: 20rem;
      max-width: 20rem;
    }
    @include media-breakpoint-up(lg) {
      position: relative;
      border-radius: $radius-default;
      min-width: 28rem;
      max-width: 28rem;
      height: 4.8rem;
    }
  }

  &__organisation--clickable {
    cursor: pointer
  }

  &__organisation-logo {
    min-width: 3.2rem;
    max-width: 3.2rem;
    height: 3.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: $radius-default;
    border: .1rem solid $neutral-40;
  }

  &__organisation-default-logo {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: $radius-default;
    border: .1rem solid $neutral-40;
    min-width: 3.2rem;
    max-width: 3.2rem;
    height: 3.2rem;
    background-color: $neutral-60;
    span {
      color: $neutral-0;
    }
  }

  span.button-organisations__organisation-dropdown-icon.icon-component {
    margin-left: auto;
    width: auto;
    color: $primary-60;

    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
    svg {
      width: 1.4rem;
    }
  }

  &__organisation-dropdown-icon {
    display: flex;
    transition: .2s all ease-in-out;
  }

  &__organisation-dropdown-icon--toggled {
    transform: scale(1, -1);
    transition: .2s all ease-in-out;
  }

  span.button-organisations__organisation-dropdown-icon--hide {
    display: none;
  }

  &__organisation-name {
    display: none;
    margin: 0 1rem;
    width: 100%;
    text-align: left;
    @include media-breakpoint-up(md) {
      display: inline;
      width: 100%;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 7.8rem;
    left: 2rem;
    right: 2rem;
    background-color: $neutral-0;
    border-radius: $radius-default;
    box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.10), 0px 10px 15px -3px rgba(16, 24, 40, 0.10);
    z-index: map-get($zindex, modal);
    padding: .8rem 0 2.4rem;

    .button-organisations__organisation-name {
      display: flex;
    }

    @include media-breakpoint-up(lg) {
      top: 6rem;
      right: 2.4rem;
      left: initial;
      width: 48rem;
      border: .1rem solid $neutral-20;
      padding: .8rem 0;
    }
  }

  &__menu-header {
    display: flex;
    justify-content: flex-end;
    padding: .8rem 1.6rem;

    &__icon-component {
      width: 2.4rem;
      color: $neutral-60;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: .8rem 1.6rem;
    margin: .4rem 0;
    color: $neutral-80;

    &--selected {
      background-color: $info-10;
    }

    &:hover {
      background-color: $secondary-10;
    }

    .button-organisations__organisation-logo {
      align-self: flex-start;
    }

    .button-organisations__organisation-default-logo {
      align-self: flex-start;
    }
  }

  &__menu-item--mobile-hidden {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  &__divider {
    display: flex;
    width: 100%;
    height: .1rem;
    background-color: rgba($neutral-100, .2);
    border: none;
    margin: 0;
    @include media-breakpoint-up(md) {
      width: calc(100% - 2.8rem);
      margin: 0 1.4rem;
    }
  }

  &__scrim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    opacity: 0.2;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__tooltip.v-tooltip > .v-overlay__content {
    font-size: 1.2rem;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
